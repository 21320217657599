<template>
  <div>
    <FullscreenLoader v-if="loading" />
    <FixedLoader v-if="pending" />
    <FullscreenLoader v-if="false" />
    <FixedLoader v-if="false" />
    <div v-if="!loading">
      <AppHeader :username="username" />
      <div class="container">
        <ExpenseInput
          :items="items"
          @add="addItem"
        />
        <ExpenseTable
          v-if="items.length > 0"
          :items="items"
          @removeItem="removeItem"
          @updateItemDate="updateItemDate"
          @updateItemAmount="updateItemAmount"
          @updateItemDescription="updateItemDescription"
        />
      </div>
      <ExpenseGraph v-if="items.length > 0" :items="items" />
    </div>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import ExpenseInput from "../components/ExpenseInput.vue";
import ExpenseTable from "../components/ExpenseTable.vue";
import ExpenseGraph from "../components/ExpenseGraph.vue";
import FullscreenLoader from "../components/FullscreenLoader.vue";
import FixedLoader from "../components/FixedLoader.vue";
import { getProfile, serverStorageUpdate } from "@/api";

export default {
  components: {
    AppHeader,
    ExpenseInput,
    ExpenseTable,
    ExpenseGraph,
    FullscreenLoader,
    FixedLoader
  },
  data() {
    return {
      items: [],
      username: {},
      loading: true,
      pending: false
    };
  },
  methods: {
    removeItem(uuid) {
      const index = this.items.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
      this.saveToServerStorage();
    },

    updateItemDate(uuid, date) {
      const index = this.items.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        this.items[index].date = date;
      }
      this.saveToServerStorage();
    },

    updateItemAmount(uuid, amount) {
      const index = this.items.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        this.items[index].amount = amount;
      }
      this.saveToServerStorage();
    },

    updateItemDescription(uuid, description) {
      const index = this.items.findIndex((item) => item.uuid === uuid);
      if (index !== -1) {
        this.items[index].description = description;
      }
      this.saveToServerStorage();
    },

    addItem(item) {
      const repetitions = item.repetitions || 1;
      for (let i = 0; i < repetitions; i++) {
        const newItem = {
          uuid: new Date().getTime() + Math.floor(Math.random() * 1000),
          ...item,
          date: this.addInterval(new Date(item.date), item.interval, i),
        };
        delete newItem.repetitions;
        this.items.push(newItem);
      }
      this.items.sort((a, b) => new Date(a.date) - new Date(b.date));
      this.saveToServerStorage();
    },
    addInterval(date, interval, count) {
      switch (interval) {
        case "daily":
          date.setDate(date.getDate() + count);
          break;
        case "weekly":
          date.setDate(date.getDate() + count * 7);
          break;
        case "monthly":
          date.setMonth(date.getMonth() + count);
          break;
      }
      return date;
    },
    async saveToServerStorage() {
      this.pending = true
      await serverStorageUpdate(this.items);
      this.pending = false
    },
    loadFromLocalStorage() {
      const savedData = localStorage.getItem("expense-items");
      if (savedData) {
        this.items = JSON.parse(savedData);
      }
    },
    addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    },
  },
  async mounted() {
    this.loading = true;
    const { serverStorage, username } = await getProfile();
    let items = JSON.parse(serverStorage);
    this.items = Array.isArray(items) ? items : [];
    this.username = username;
    this.loading = false;
  },
};
</script>
