import Vue from 'vue';
import Router from 'vue-router';
import LoginPage from '../pages/LoginPage.vue';
import MainPage from '../pages/MainPage.vue';
import NotAFoundPage from '../pages/NotAFoundPage.vue';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'MainPage',
            meta: { requiresAuth: true },
            component: MainPage
        },
        {
            path: '/login',
            name: 'LoginPage',
            component: LoginPage
        },
        {
            path: '*',
            name: '404',
            component: NotAFoundPage
        }
    ]
});



router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!localStorage.getItem('token')) {
        next('/login');
      } else {
        next();
      }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
      if (localStorage.getItem('token')) {
        next('/');
      } else {
        next();
      }
    } else {
      next();
    }
});

export default router;