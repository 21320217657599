<template>
  <div
    class="fixed-loader"
  >
    <div class="spinner-border spinner-border-sm text-primary" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FixedLoader",
};
</script>

<style>
  .fixed-loader {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
  }
  .fixed-loader .spinner-border {
    display: block;
  }
</style>
