<template>
  <div>
    <div class="container">
      <div class="card-header mb-4">
        <h6 class="mb-0" @click="show = !show">
          {{ show ? "-" : "+" }} Таблица транзакций
        </h6>
      </div>
    </div>
    <div  v-show="show" style="margin: 0 auto; padding: 0 15px; overflow-x:auto;">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="startDatePrepend">Начальная дата:</span>
              </div>
              <input type="date" class="form-control" aria-describedby="startDatePrepend" v-model="startDateString" @change="updateDates">
            </div>
          </div>
          <div class="col-12 col-lg-6 mb-3">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="endDatePrepend">Конечная дата:</span>
              </div>
              <input type="date" class="form-control" aria-describedby="endDatePrepend" v-model="endDateString" @change="updateDates">
            </div>
          </div>
        </div>
      </div>
      <div ref="plotlyGraph" style="min-height: 400px; min-width: 1400px; height: 100vh;"></div>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js/dist/plotly";

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      startDate: new Date(),
      endDate: new Date(),
      startDateString: '',
      endDateString: '',
    };
  },
  created() {
    const currentDate = new Date();
    this.startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
    this.startDateString = this.startFormatDate(this.startDate);
    this.endDateString = this.endDate.toISOString().split('T')[0];
  },
  mounted() {
    this.drawGraph();
  },
  watch: {
    items: {
      deep: true,
      handler: function () {
        this.drawGraph();
      },
    },
    startDate() {
      this.drawGraph();
    },
    endDate() {
      this.drawGraph();
    }
  },
  methods: {
    updateDates() {
      this.startDate = new Date(this.startDateString);
      this.endDate = new Date(this.endDateString);
    },
    startFormatDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1; // Месяцы начинаются с 0
      month = month < 10 ? '0' + month : month; // Добавляем ведущий ноль, если нужно
      return `${year}-${month}-01`; // Формируем строку в формате YYYY-MM-DD
    },
    drawGraph() {
      // Plotly.purge(this.$refs.plotlyGraph);

      const items = JSON.parse(JSON.stringify(this.items));
      const dates = [...new Set(items.map((item) => item.date))].sort();
      const descriptions = [...new Set(items.map((item) => item.description))];

      // Создание столбцов для каждого описания
      const barTraces = descriptions.map((description) => {
        const dailySums = dates.map((date) =>
          items
            .filter(
              (item) => item.date === date && item.description === description
            )
            .reduce((sum, item) => sum + item.amount, 0)
        );

        return {
          x: dates,
          y: dailySums,
          type: "bar",
          name: description,
          text: dailySums.map(
            (sum, i) =>
              `Дата: ${new Date(
                dates[i]
              ).toLocaleDateString()}, Описание: ${description}, Сумма: ${sum}`
          ),
          hoverinfo: "text",
        };
      });

      // Создание линейного графика для кумулятивных сумм
      const cumulativeSums = dates.map((date) => {
        return items
          .filter((item) => new Date(item.date) <= new Date(date))
          .reduce((sum, item) => sum + item.amount, 0);
      });

      const trace2 = {
        x: dates,
        y: cumulativeSums,
        type: "scatter",
        mode: "lines+markers",
        name: "Баланс дня",
        line: {
          shape: "hv",
          color: '#007bff',
        },
      };

      // Определение текущей даты
    const today = new Date();

    // Нахождение индекса самой последней даты до текущей
    const todayIndex = dates.reduce((lastIndex, currentDate, index) => {
        const date = new Date(currentDate);
        return date <= today ? index : lastIndex;
    }, -1);

    // Получение баланса для этой даты
    const todayBalance = todayIndex >= 0 ? cumulativeSums[todayIndex] : 0;

    // Добавление точки для текущего баланса
    const currentDayTrace = {
        x: [today, today],
        y: [0, todayBalance], // или другой диапазон, который подходит для вашего графика
        type: 'scatter',
        mode: 'lines',
        name: 'Текущий день',
        line: {
            color: '#007bff', // Выберите цвет линии
            width: 2,
            dash: 'dot' // или другой стиль линии
        }
    };


      // Объединение всех трасс в один график
      const layout = {
        barmode: "stack",
        title: "Доходы и расходы",
        xaxis: {
            range: [this.startDate.toISOString().split('T')[0], this.endDate.toISOString().split('T')[0]],
            type: 'date'
        }
      };

      Plotly.newPlot(this.$refs.plotlyGraph, [...barTraces, trace2, currentDayTrace], layout);

      this.$refs.plotlyGraph.on('plotly_click', function (data) {
        data.points?.forEach(i => {
          console.log('i.data.uuid', i.data.uuid)
          location.href = `#${i.data.uuid}`
        })
      });
    },
  },
};
</script>
