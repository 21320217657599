<template>
  <div>
    <div class="container mt-5">
      <h2 class="mb-3">{{ isLoginMode ? "Авторизация" : "Регистрация" }}</h2>
      <div class="form-group">
        <label for="username">Имя пользователя</label>
        <input
          id="username"
          v-model="username"
          type="text"
          class="form-control"
          :class="{ 'is-invalid': errorEnter }"
          placeholder="Введите имя пользователя"
        />
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <input
          id="password"
          v-model="password"
          type="password"
          class="form-control"
          :class="{ 'is-invalid': errorEnter }"
          placeholder="Введите пароль"
        />
        <div v-if="errorEnter" class="invalid-feedback">
          Некорректное имя пользователя или пароль.
        </div>
      </div>
      <button @click="enter" class="btn btn-primary">
        <div v-if="pending" class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden"></span>
        </div>
        {{ isLoginMode ? "Войти" : "Регистрация" }}
      </button>
      <button @click="toggleMode" class="btn btn-link">
        {{ isLoginMode ? "Регистрация" : "Авторизация" }}
      </button>
    </div>
  </div>
</template>

<script>
import { login, register } from "../api/index";

export default {
  name: "PageLogin",
  data() {
    return {
      username: "",
      password: "",
      errorEnter: false,
      isLoginMode: true,
      pending: false,
    };
  },
  computed: {
    form() {
      return {
        username: this.username,
        password: this.password,
      };
    },
  },
  watch: {
    form() {
      this.errorEnter = false;
    },
  },
  methods: {
    async enter() {
      this.pending = true;
      if (this.isLoginMode) {
        try {
          await login(this.username, this.password);
        } catch (error) {
          this.errorEnter = true;
          this.pending = false;
        } finally {
          this.pending = false;
        }
      } else {
        try {
          const response = await register(this.username, this.password);

          if (response) {
            alert("Регистрация успешна. Теперь вы можете войти в систему.");
            this.username = "";
            this.password = "";
            this.isLoginMode = true;
          }
        } catch (error) {
          this.errorEnter = true;
        } finally {
          this.pending = false;
        }
      }
    },
    toggleMode() {
      this.isLoginMode = !this.isLoginMode;
      this.errorEnter = false;
    },
  },
};
</script>
