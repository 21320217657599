<template>
  <div>
    <div class="card-header mb-4">
        <h6 class="mb-0" @click="show = !show">
            {{ show ? "-" : "+" }} Список транзакций
        </h6>
    </div>
    <div v-show="show" class="table-responsive">
      <div class="row">
        <div class="col-12 col-lg-3 mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="startDatePrepend">Начальная:</span>
            </div>
            <input type="date" class="form-control" aria-describedby="startDatePrepend" v-model="startDateString" @change="updateDates">
          </div>
        </div>
        <div class="col-12 col-lg-3 mb-3">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="endDatePrepend">Конечная:</span>
            </div>
            <input type="date" class="form-control" aria-describedby="endDatePrepend" v-model="endDateString" @change="updateDates">
          </div>
        </div>
        <div class="col-12 col-lg-auto mb-3">
          <button class="btn btn-outline-primary mr-1" @click="setThisMonth">Этот месяц</button>
          <button class="btn btn-outline-primary mr-1" @click="setNextMonth">Следующий</button>
          <button class="btn btn-outline-primary mr-1" @click="setThisYear">Этот год</button>
        </div>
        <div class="col-12 col-lg-auto mb-3">
          <button class="btn btn-outline-secondary" @click="toggleView">{{ viewMode === 'detailed' ? 'Коротко' : 'Подробно' }}</button>
        </div>
      </div>

        <table v-if="viewMode === 'detailed'" class="table table-striped" style="min-width: 1000px">
            <thead>
                <tr>
                    <th>Дата</th>
                    <th>Сумма операции</th>
                    <th>Баланс дня</th>
                    <th>
                    <Multiselect
                      style="width: 100%"
                      v-model="selectedDescription"
                      :options="uniqueDescriptions"
                      placeholder="Выберите описание"
                    ></Multiselect>
                  </th>
                    <th>Периодичность</th>
                    <th>Удалить</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in filteredList" :key="`${item.date}_${index}`" :id="item.uuid">
                    <td>
                        <input type="date" class="form-control" :value="formatISODateToInputDate(item.date)" @change="updateItemDate($event, item.uuid)" />
                    </td>
                    <td>
                        <input type="number" class="form-control" :value="item.amount" @change="updateItemAmount($event, item.uuid)" />
                    </td>
                    <td>{{ dailySum(item.date) }}</td>
                    <td>
                        <input type="text" class="form-control" :value="item.description" @change="updateItemDescription($event, item.uuid)" />
                    </td>
                    <td>{{ intervalLabel(item.interval) }}</td>
                    <td>
                        <button class="btn btn-danger btn-sm" @click="removeItem(item.uuid)">Удалить</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <table v-else class="table table-striped" style="min-width: 1000px">
            <thead>
                <tr>
                    <th>Количество</th>
                    <th>Сумма</th>
                    <th>Описание</th>
                    <th>Действия</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(item, index) in aggregatedList">
                    <tr :key="`aggregated-${index}`">
                        <td>{{ item.count }}</td>
                        <td>{{ item.totalAmount }}</td>
                        <td>{{ item.description }}</td>
                        <td>
                            <button class="btn btn-secondary btn-sm" @click="toggleItem(item.description)">Развернуть</button>
                        </td>
                    </tr>
                    <template v-if="expandedItems.includes(item.description)">
                      <tr v-for="detail in item.details" :key="`detail-${detail.uuid}`">
                        <td>
                            <input type="date" class="form-control" :value="formatISODateToInputDate(detail.date)" @change="updateItemDate($event, detail.uuid)" />
                        </td>
                        <td>
                            <input type="number" class="form-control" v-model="detail.amount" @change="updateItemAmount($event, detail.uuid)" />
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="detail.description" @change="updateItemDescription($event, detail.uuid)" />
                        </td>
                        <td>
                            <button class="btn btn-danger btn-sm" @click="removeItem(detail.uuid)">Удалить</button>
                        </td>
                    </tr>
                    </template>
                </template>
            </tbody>
        </table>
        <div class="totals-summary">
            <p><strong>Всего трат:</strong> {{ totalExpenses }}</p>
            <p><strong>Всего пополнений:</strong> {{ totalIncome }}</p>
        </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  props: ["items"],
  components: { Multiselect },
  data() {
    return {
      show: true,
      selectedDescription: null,
      startDate: new Date(),
      endDate: new Date(),
      startDateString: '',
      endDateString: '',
      viewMode: 'summary',
      expandedItems: [],
    };
  },
  computed: {
    uniqueDescriptions() {
      return [...new Set(this.items.map((item) => item.description))];
    },
    aggregatedList() {
      const filteredByDate = this.items.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= this.startDate && itemDate <= this.endDate;
      });

      const result = filteredByDate.reduce((acc, item) => {
        const key = item.description;
        if (acc[key]) {
          acc[key].totalAmount += item.amount;
          acc[key].count += 1;
          acc[key].details.push({
            uuid: item.uuid,
            date: item.date,
            amount: item.amount,
            description: item.description,
          });
        } else {
          acc[key] = {
            description: item.description,
            totalAmount: item.amount,
            count: 1,
            details: [{
              uuid: item.uuid,
              date: item.date,
              amount: item.amount,
              description: item.description,
            }],
          };
        }
        return acc;
      }, {});
      return Object.values(result);
    },
    filteredList() {
      let filteredByDescription = this.items;
      if (this.selectedDescription) {
        filteredByDescription = this.items.filter(
          item => item.description === this.selectedDescription
        );
      }

      return filteredByDescription.filter(item => {
        const itemDate = new Date(item.date);
        const isDateValid = !isNaN(itemDate.getTime());
        const isWithinDateRange = isDateValid && itemDate >= this.startDate && itemDate <= this.endDate;
        return isWithinDateRange;
      });
    },
    totalExpenses() {
      return this.items
        .filter(item => {
          const itemDate = new Date(item.date);
          return item.amount < 0 && itemDate >= this.startDate && itemDate <= this.endDate;
        })
        .reduce((sum, item) => sum + item.amount, 0);
    },
    totalIncome() {
      return this.items
        .filter(item => {
          const itemDate = new Date(item.date);
          return item.amount > 0 && itemDate >= this.startDate && itemDate <= this.endDate;
        })
        .reduce((sum, item) => sum + item.amount, 0);
    },
  },
  methods: {
    toggleItem(description) {
      const index = this.expandedItems.indexOf(description);
      if (index > -1) {
        this.expandedItems.splice(index, 1);
      } else {
        this.expandedItems.push(description);
      }
    },
    toggleView() {
      this.viewMode = this.viewMode === 'detailed' ? 'summary' : 'detailed';
    },
    updateDates() {
      this.startDate = new Date(this.startDateString);
      this.endDate = new Date(this.endDateString);
    },
    formatISODateToInputDate(isoDateString) {
      const date = new Date(isoDateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Месяцы начинаются с 0
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    startFormatDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1; // Месяцы начинаются с 0
      month = month < 10 ? '0' + month : month; // Добавляем ведущий ноль, если нужно
      return `${year}-${month}-01`; // Формируем строку в формате YYYY-MM-DD
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    updateItemDate(event, index) {
      this.$emit("updateItemDate", index, event.target.value);
    },
    updateItemAmount(event, index) {
      this.$emit("updateItemAmount", index, Number(event.target.value));
    },
    updateItemDescription(event, index) {
      this.$emit("updateItemDescription", index, event.target.value);
    },
    removeItem(index) {
      this.$emit("removeItem", index);
    },
    intervalLabel(interval) {
      const labels = {
        none: "Не повторять",
        daily: "Ежедневно",
        weekly: "Еженедельно",
        monthly: "Ежемесячно",
      };
      return labels[interval] || "Неизвестно";
    },
    dailySum(date) {
      return this.items
        .filter((item) => item.date === date)
        .reduce((sum, item) => sum + item.amount, 0);
    },
    cumulativeSum(index) {
      return this.items
        .slice(0, index + 1)
        .reduce((sum, item) => sum + item.amount, 0);
    },
    setThisMonth() {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0); // Последний день текущего месяца
      this.startDateString = this.formatDateToInputValue(startOfMonth);
      this.endDateString = this.formatDateToInputValue(endOfMonth);
      this.updateDates();
    },
    setNextMonth() {
      const now = new Date();
      const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      const endOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 2, 0); // Последний день следующего месяца
      this.startDateString = this.formatDateToInputValue(startOfNextMonth);
      this.endDateString = this.formatDateToInputValue(endOfNextMonth);
      this.updateDates();
    },
    setThisYear() {
      const now = new Date();
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const endOfYear = new Date(now.getFullYear(), 11, 31); // 31 декабря текущего года
      this.startDateString = this.formatDateToInputValue(startOfYear);
      this.endDateString = this.formatDateToInputValue(endOfYear);
      this.updateDates();
    },
    formatDateToInputValue(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  },
  created() {
    const currentDate = new Date();
    this.startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
    this.startDateString = this.startFormatDate(this.startDate);
    this.endDateString = this.endDate.toISOString().split('T')[0];
  }
};
</script>
