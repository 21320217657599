<template>
  <div
    class="d-flex align-items-center justify-content-center"
    style="width: 100%; height: 100vh"
  >
    <div class="text-center">
      <h1 class="display-6 font-weight-bold">404</h1>
      <p class="h4 mb-4">Страница не найдена.</p>
      <router-link to="/" class="btn btn-primary"
        >Вернуться на главную</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
