<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light mb-3">
    <div class="container">
      <img src="/apple-touch-icon.png" class="app-logo">
      <div class="ml-auto d-flex align-items-center">
        <div class="navbar-text mr-3">
          <div class="avatar-circle">
            <span class="initials">{{ username.toUpperCase() }}</span>
          </div>
        </div>
        <button class="btn btn-outline-danger" type="button" @click="logout()">
          Выйти
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    username: {
      type: String,
      default: "",
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push({ name: "LoginPage" });
    },
  },
};
</script>

<style>
.app-logo {
    width: 40px;
    border-radius: 100px;
}
</style>
