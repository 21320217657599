<template>
  <div
    class="d-flex align-items-center justify-content-center"
    style="width: 100%; height: 100vh"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullscreenLoader",
};
</script>

<style></style>
