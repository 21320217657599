<template>
  <div class="mb-3">
    <div class="card-header mb-4">
      <h6 class="mb-0" @click="show = !show">
        {{ show ? "-" : "+" }} Внести транзакцию
      </h6>
    </div>
    <div v-show="show">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Дата</span>
        </div>
        <input type="date" class="form-control" v-model="date" />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Сумма</span>
        </div>
        <input type="number" class="form-control" v-model="amount" />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputType">Тип</label>
        </div>
        <select class="custom-select" id="inputType" v-model="type">
          <option value="income">Доход</option>
          <option value="expense">Расход</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputInterval"
            >Периодичность</label
          >
        </div>
        <select class="custom-select" id="inputInterval" v-model="interval">
          <option value="none">Не повторять</option>
          <option value="daily">Ежедневно</option>
          <option value="weekly">Еженедельно</option>
          <option value="monthly">Ежемесячно</option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputRepetitions"
            >Повторений</label
          >
        </div>
        <input
          type="number"
          class="form-control"
          id="inputRepetitions"
          v-model="repetitions"
          min="1"
          placeholder="Количество повторений"
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputDescription"
            >Описание</label
          >
        </div>
        <vue-bootstrap-typeahead
          class="flex-grow-1"
          :data="uniqueDescriptions"
          v-model="description"
          @hit="onSelectDescription"
          placeholder="Описание"
        />
      </div>
      <button class="btn btn-primary" @click="add">Добавить</button>
    </div>
  </div>
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';

export default {
  props: ["items"],
  components: { VueBootstrapTypeahead  },
  data() {
    return {
      date: this.getCurrentDate(),
      amount: 0,
      type: "income",
      interval: "none",
      repetitions: 1,
      description: "",
      show: true,
    };
  },
  computed: {
    uniqueDescriptions() {
      return [...new Set(this.items.map(item => item.description))];
    },
  },
  methods: {
    add() {
      this.$emit("add", {
        date: this.date,
        amount: this.type === "income" ? +this.amount : -this.amount,
        interval: this.interval,
        repetitions: this.repetitions,
        description: this.description,
      });
    },
    onSelectDescription(hit) {
      this.description = hit;
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
