import axios from "axios";
import router from "../router";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
});

api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
        if (error.response && error.response.status === 401
            || error.response && error.response.status === 500) {
        localStorage.removeItem('token');
        if (router.currentRoute.name !== 'LoginPage') {
            router.push({name: 'LoginPage'});
        }
      }

      return Promise.reject(error);
    }
);

export async function login(user, password) {
    try {
        const response = await api.post('/login', {
            username: user,
            password: password
        });

        if (response.status === 200) {
            // Успешный вход
            localStorage.setItem('token', response.data.token);
            api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
            router.push('/');
            return response.data;
        } else {
            // Обработка других статусов ответа
            console.log('Login failed with status:', response.status);
            return false;
        }
    } catch (error) {
        // Обработка ошибок запроса
        if (error.response) {
            // Сервер вернул ответ с кодом статуса вне диапазона 2xx
            console.log('Error status:', error.response.status);
            console.log('Error data:', error.response.data);
            throw error;

        } else if (error.request) {
            // Запрос был сделан, но ответ не был получен
            console.log('No response:', error.request);
            alert('Неизвестная ошибка');
        } else {
            // Ошибка в настройке запроса
            console.log('Error', error.message);
        }
        return false;
    }
}

export async function register(user, password) {
    try {
        const response = await api.post('/register', {
            username: user,
            password: password
        });

        if (response.status === 200 || response.status === 201) {
            return response.data;
        } else {
            console.log('Register failed with status:', response.status);
            alert(response.response.data);
            return false;
        }
    } catch (error) {
        if (error.response) {
            console.log('Error status:', error.response.status);
            console.log('Error data:', error.response.data);
            alert(error.response.data);
        } else if (error.request) {
            console.log('No response:', error.request);
        } else {
            console.log('Error', error.message);
        }
        return false;
    }
}


export async function getProfile() {
    try {
        const {data} = await api.get('/profile');
        return data;
    } catch {
        return false;
    }
}

export async function serverStorageUpdate(serverStorage) {
    try {
        const { data } = await api.post('/serverStorageUpdate', {
            serverStorage
        });
        return data;
    } catch {
        return false;
    }
}